<script>
export default {
  metaInfo: {
    title: "Network Quality Measures",
  }
};
</script>

<script setup>
import PhpServiceTableView from '@/components/basic/php-wrappers/PhpServiceTableView.vue';
</script>

<template>
  <PhpServiceTableView url="?f=configuration&f2=ratSimManagement&f3=simModemQuality"/>
</template>

